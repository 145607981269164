import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Routes, Route } from "react-router-dom";
import CreateCampaign from "../Pages/CreateCampaign";
import CampaignsListGrid from "../Pages/CampaignDashboard";
import ProofTemplate from "../Pages/ProofTemplate";
import UploadContacts from "../Pages/UploadContacts";
import Home from "../Pages/Home.jsx";
import "../CustomStyle.css";
import PrintContent from "../Pages/PrintContent.jsx";
import { Alert } from "react-bootstrap";
import CreateNewUser from "../Pages/CreateNewUser.jsx";
import ExistingUsers from "../Pages/ExistingUsers.jsx";
const AppLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [secretKey, setSecretKey] = useState(null);
  const [authError, setAuthError] = useState(null);
  let user;
  useEffect(() => {
    setAuthError(null);

    const urlSecretKey = window.location.hash.replace("#", "");
    const storedSecretKey = sessionStorage.getItem("login_code");

    if (urlSecretKey) {
      setSecretKey(urlSecretKey); // If secretKey is in URL, use it
      sessionStorage.setItem("login_code", urlSecretKey); // Store it in sessionStorage
    } else if (storedSecretKey) {
      setSecretKey(storedSecretKey); // If secretKey is in sessionStorage, use it
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  if (authError) {
    return (
      <Alert variant="danger" style={{ width: "80%", margin: "4%" }}>
        {authError}
      </Alert>
    );
  }
  if (secretKey === "rnEj5G") {
    user = "admin";
  }

  return (
    <>
      {secretKey ? (
        <div style={{ display: "flex", height: "100vh" }}>
          {/* Set to 100vh for full height */}

          <div
            style={{
              width: isSidebarOpen
                ? window.innerWidth <= 768
                  ? "70%" // Fixed width for mobile devices
                  : "25%" // Percentage width for larger screens
                : "0",
              transition: "width 0.3s ease",
            }}
          >
            <Sidebar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              user={user}
            />
          </div>

          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              margin: isSidebarOpen
                ? window.innerWidth <= 768
                  ? "20px 10px 0 10px" // Adjust margins for mobile when sidebar is open
                  : "0 10px 0 10px" // Default margins for larger screens
                : "40px 20px 0 20px", // Margin when sidebar is closed
              transition: "margin 0.3s ease",
              width:
                window.innerWidth <= 768
                  ? "90%" // Fixed width for mobile devices
                  : "75%", // Percentage width for larger screens
            }}
          >
            {/* Optional: Add overflow for scrolling */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/create_campaign"
                element={<CreateCampaign secretKey={secretKey} />}
              />
              <Route
                path="/upload_contacts"
                element={<UploadContacts secretKey={secretKey} />}
              />
              <Route
                path="/campaign_dashboard"
                element={<CampaignsListGrid secretKey={secretKey} />}
              />
              <Route
                path="/proof_template"
                element={<ProofTemplate secretKey={secretKey} />}
              />
              <Route
                path="/generate_print_collaterals"
                element={<PrintContent secretKey={secretKey} />}
              />
              <Route
                path="/admin_create_user"
                element={<CreateNewUser secretKey={secretKey} />}
              />
              <Route
                path="/admin_existing_users"
                element={<ExistingUsers secretKey={secretKey} />}
              />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      )}
    </>
  );
};

export default AppLayout;
