import React from "react";
import { PropTypes } from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const EntityDetails = ({ inputs }) => {
  return (
    <div>
      {inputs.map((input, index) => (
        <InputGroup className="mb-3" key={index}>
          <InputGroup.Text style={{ width: "200px" }}>
            {input.label}
          </InputGroup.Text>
          <Form.Control value={input.value ?? " "} readOnly />
        </InputGroup>
      ))}
    </div>
  );
};

export default EntityDetails;

EntityDetails.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
};
