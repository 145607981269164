import { WidthFull } from "@mui/icons-material";
import axios from "axios";

const APIENDPOINT = "developer19-linqr.reachpersona.com";

export function validateUrl(url) {
  const regex = new RegExp(
    /^(https|ftp):\/\/([A-Z0-9][A-Z0-9-]{0,61}[A-Z0-9]\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|(\[?[A-F0-9]*:[A-F0-9:]+\]?)\b(?::\d+)?(\/[A-Z0-9+&@#\/%=~_|$?!:,.]*)?$/i
  );

  // Basic regex checks
  if (!regex.test(url)) {
    return false;
  }

  try {
    const parsedUrl = new URL(url);

    // Check if the protocol is valid
    if (!["http:", "https:", "ftp:"].includes(parsedUrl.protocol)) {
      return false;
    }

    // Exclude localhost, loopback, or local network addresses
    const excludedHosts = ["localhost", "127.0.0.1", "::1"];
    if (
      excludedHosts.includes(parsedUrl.hostname) ||
      parsedUrl.hostname.startsWith("192.168.") ||
      parsedUrl.hostname.startsWith("10.")
    ) {
      return false;
    }

    // Check for valid TLD
    const tld = parsedUrl.hostname.split(".").pop();
    if (tld.length < 2) {
      return false;
    }

    return true;
  } catch (e) {
    // If URL parsing fails, it's not a valid URL
    return false;
  }
}

export const fetchCampaignData = async (secretKey) => {
  try {
    const response = await axios.get(`https://${APIENDPOINT}/campaigns`, {
      params: {
        secret_key: secretKey,
      },
    });
    switch (response.status) {
      case 200:
        const data = await response.data;
        return data;

      case 400:
        return null;

      case 404:
        return null;

      case 500:
        return null;

      default:
        return null;
    }
  } catch (error) {
    return null;
  }
};

export const campaignGridColumns = {
  name: "Campaign",
  total: "Contacts",
};

export const respondedGridColumns = {
  fullname: "Full Name",
  street: "Street",
};

export const contactVisitsGridColumns = {
  timestamp: "TimeStamp",
  qrcode: "Qrcode-id",
  campaign: "Campaign",
};

export const existingUsersGridColumns = {
  cid: "Customer ID",
  name: "Customer",
  email: "E-mail address",
  phone: "Contact number",
  secretKey: "Scere Key",
  tpx_id: "Tracking pixel",
  unique_id: "Streamlit user ID",
  timestamp: "Created",
};
export const generateGridColumns = (mappingConfig) => {
  return Object.keys(mappingConfig).map((key) => ({
    field: key,
    headerName: mappingConfig[key],
    flex: 1,
    minWidth: 150,
  }));
};

export const campaignRowMapping = {
  name: "name",
  total: "total",
};
export const respondedRowMapping = {
  fullname: "greeting_name",
  street: "ma-addr_line1",
};
export const contactVisitsRowMapping = {
  timestamp: "timestamp",
  qrcode: "path",
  campaign: "campaign",
};
export const existingUsersRowMapping = {
  cid: "cid",
  name: "name",
  email: "email",
  phone: "phone",
  secretKey: "secret_key",
  tpx_id: "tpx_id",
  unique_id: "unique_id",
  timestamp: "timestamp",
};

export const generateGridRows = (data, mappingConfig, startIndex = 1) => {
  return data.map((record, index) => {
    const row = { id: startIndex + index };
    Object.keys(mappingConfig).forEach((key) => {
      row[key] = record[mappingConfig[key]];
    });
    return row;
  });
};
export const campaignMapping = {
  name: "Campaign",
  total: "Contacts",
  timestamp: "Created",
  destination_url: "Destination/Lamding Page",
};
export const respondedMapping = {
  timestamp: "TimeStamp",
  id: "qrcode-id",
  envelope_name: "envelope_name",
  filename_row_unique_id: "filename_row_unique_id",
  greeting_name: "greeting_name",
  "ma-addr_line1": "ma-addr_line1",
  "ma-city": "ma-city",
  "ma-state": "ma-state",
  "ma-zip": "ma-zip",
  visits: "visits",
};

export const contactVisitsMapping = {
  timestamp: "TimeStamp",
  path: "qrcode-id",
  country: "country",
  user_agent: "user-agent",
  city: "city",
  region: "region",
};

export const generateFormData = (selectedRow, mappingConfig) => {
  return Object.keys(mappingConfig).map((key) => ({
    label: mappingConfig[key],
    value: selectedRow[key],
  }));
};
