import React from "react";

function Home() {
  return (
    <div className="home-container">
      <h1 className="home-heading">TraQR - Trackable Print using QRcodes</h1>

      <h4 className="home-subheading">Track prospects who scanned QR code</h4>
      <ul className="home-list">
        <li>Define campaign and upload prospect's name and address.</li>
        <li>
          Generate and send your print collaterals with unique QR code for each
          prospect per campaign.
        </li>
        <li>
          Get to know when interested prospects scan QRcode and land on your
          landing page.
        </li>
        <li>Visit the dashboard to get campaign stats.</li>
      </ul>
    </div>
  );
}

export default Home;
