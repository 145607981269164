import React, { useState, useEffect } from "react";
import { Spinner, Alert } from "react-bootstrap";
import { FormControl, Select, MenuItem, Typography } from "@mui/material";
import "../CustomStyle.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { templatesData } from "../utils/TemplatesData";
import { PDFDocument } from "pdf-lib";
import JSZip from "jszip";
import { saveAs } from "file-saver";

function PrintContent({ secretKey }) {
  const [error, setError] = useState(null);
  const [campaignsData, setCampaignsData] = useState([]);
  const [existingTemplates, setExistingTemplates] = useState([]);
  const [campaignInfo, setCampaignInfo] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateConfig, setTemplateConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [zipBlob, setZipBlob] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const dropdownStyles = {
    borderRadius: "8px",
    border: "1px solid #ccc",
    padding: "8px ",
    fontSize: "14px",
    backgroundColor: "#f9f9f9",
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  };

  // let secretKey = window.location.hash.replace("#", "");
  const APIENDPOINT = "developer19-linqr.reachpersona.com";

  const menuItemStyles = {
    padding: "10px 16px",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#e0f7fa",
      color: "#00796b",
    },
  };

  // fetch existing campaigns
  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(`https://${APIENDPOINT}/campaigns`, {
          params: {
            secret_key: secretKey,
          },
        });

        switch (response.status) {
          case 200:
            const data = await response.data;
            setCampaignsData(data.records);
            console.log("fetched campigns data", data.records);
            break;

          case 404:
            setCampaignInfo(" No existing campaigns were found for this user.");
            break;

          case 500:
            setError(
              "Something went wrong while attempting to display data on the screen."
            );
            break;

          default:
            setError(
              "Something went wrong while attempting to display data on the screen."
            );
        }
      } catch (error) {
        setError(
          "Something went wrong while attempting to display data on the screen."
        );
      }
    };
    fetchCampaignData();
  }, []);

  useEffect(() => {
    const fetchExistingTemplates = async () => {
      console.log("template secret", secretKey);
      try {
        const response = await axios.get(`https://${APIENDPOINT}/templates`, {
          params: {
            secret: secretKey,
          },
          validateStatus: (status) => status < 500,
        });
        if (response.status === 200) {
          const templatesData = await response.data;
          console.log("templates data", templatesData);
          setExistingTemplates(templatesData.records);
        } else if (response.status === 400) {
          setResponseError(response.data.message);
          return;
        } else if (response.status === 404) {
          setResponseError(
            "Please create a customized template first and then come back here"
          );
          return;
        }
      } catch (error) {
        setError(error);
      }
    };
    fetchExistingTemplates();
  }, []);

  useEffect(() => {
    fetch("/templateconfig.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        setTemplateConfig(data);
      })
      .catch((error) => console.error("Error fetching config", error));
  }, []);

  useEffect(() => {
    console.log("campign", selectedCampaign);
    console.log("template", selectedTemplate);
  }, [selectedCampaign, selectedTemplate]);

  // check user saved any template
  if (templatesData.length < 1) {
    return (
      <p>Please create a customized template first and then come back here</p>
    );
  }

  const generatePdf = async () => {
    const pdfFiles = [];
    const BATCH_SIZE = 5;
    try {
      if (!templateConfig) {
        console.log(
          "Template configuration JSON file is not available or read"
        );
      }
      const templateChoice = selectedTemplate.choice;
      const cdnLink = templateConfig.templates[templateChoice].base_url;
      const lambdaLink = templateConfig.templates[templateChoice].lambda_url;
      const contactsResponse = await getContactsForCampaign(
        secretKey,
        selectedCampaign.name
      );

      if (!Array.isArray(contactsResponse) || contactsResponse.length == 0) {
        console.error(
          "contacts returned from the API is either not a list or has no records"
        );
        return;
      }

      const batches = []; // conatain batch of arrays
      for (let i = 0; i < contactsResponse.length; i += BATCH_SIZE) {
        const batch = contactsResponse.slice(i, i + BATCH_SIZE);
        batches.push(batch); // one batch equals batchsize of contacts i.e 5
      }

      console.log("batches length", batches.length);

      for (const batch of batches) {
        console.log("processing batch", batch);
        const tasks = batch.map((contact) =>
          generatePdfForContact(contact, cdnLink, lambdaLink)
        );
        console.log("tasks", tasks);
        const results = await Promise.all(tasks);
        console.log("results", results);

        // collect results
        results.forEach((result) => {
          if (result) pdfFiles.push(result);
        });
      }
    } catch (error) {
      setError(error);
      console.log(error);
    }
    return pdfFiles;
  };

  const getContactsForCampaign = async (secretKey, campaignName) => {
    try {
      const response = await axios.get(`https://${APIENDPOINT}/records`, {
        params: {
          secret_key: secretKey,
          campaign: campaignName,
          visited: "false",
        },
      });
      if (response.status === 200) {
        const data = await response.data;
        console.log("all contacts", data);
        return data;
      } else if (response.status === 400) {
        return [];
      } else if (response.status === 404) {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  // generate pdf for a single contact asynchronously
  const generatePdfForContact = async (contact, cdnLink, lambdaLink) => {
    try {
      let queryParams = {};
      if (!contact) {
        console.log("contact is required to generate pdf");
        return;
      }
      if (
        selectedTemplate.choice === "Agent Letter - Biro" ||
        selectedTemplate.choice === "Agent Letter - Arial"
      ) {
        queryParams = {
          alias: contact.greeting_name,
          qrCodeContent: contact.trackable_url,
          id: contact.id,
          buyer_names: selectedTemplate.campaign.buyer_names,
          realtor_info: selectedTemplate.customer.realtor_info,
          signature: selectedTemplate.customer.signature,
          sigline1: selectedTemplate.customer.sigline1,
          sigline2: selectedTemplate.customer.sigline2,
          sigline3: selectedTemplate.customer.sigline3,
          sigline4: selectedTemplate.customer.sigline4,
          cta1: selectedTemplate.customer.cta1,
          cta2: selectedTemplate.customer.cta2,
          city: selectedTemplate.campaign.city,
        };
      } else if (selectedTemplate.choice === "Envelope #10") {
        queryParams = {
          alias: contact.envelope_name,
          address1: contact["ma-addr_line1"],
          id: contact.id,
          city: contact["ma-city"],
          state: contact["ma-state"],
          zip: contact["ma-zip"],
          return_address_line1: selectedTemplate.customer.return_address_line1,

          return_address_line2: selectedTemplate.customer.return_address_line2,
        };
      } else {
        console.log("The choice of template selected is not supported.");
      }
      console.log("query params", queryParams);

      const formattedUrl = appendqptoExistingUrl(cdnLink, queryParams);
      console.log("Formatted URL", formattedUrl);
      const encodedFormattedUrl = encodeURIComponent(formattedUrl);
      const formattedLambdaUrl = lambdaLink + encodedFormattedUrl;
      console.log("Formatted Lambda Url", formattedLambdaUrl);
      const responseContent = await fetchPdf(formattedLambdaUrl);
      console.log("pdf binary data", responseContent);

      if (responseContent) {
        const fileName = `${contact.path}.pdf`;
        return { fileName, content: responseContent };
      } else {
        console.log("Something went wrong while attempting to generate pdf");
        return null;
      }
    } catch (error) {
      console.log(" Getting error while generating pdf for contact", error);
    }
  };

  const appendqptoExistingUrl = (url, newQp) => {
    try {
      //parse the URL
      const parsedUrl = new URL(url);

      // Getting existing query parameters
      const queryParams = new URLSearchParams(parsedUrl.search);

      //Append new query parameters
      for (const [key, value] of Object.entries(newQp)) {
        if (Array.isArray(value)) {
          // Handle multiple values for the same key
          value.forEach((val) => queryParams.append(key, val));
        } else {
          queryParams.append(key, value);
        }
      }

      // Encode the query string and replace + with %20
      let updatedQueryString = queryParams.toString().replace(/\+/g, "%20");

      // Update the URL with the new query parameters
      parsedUrl.search = updatedQueryString;

      //Return the updated URL
      // console.log("parsed URL", parsedUrl.toString());
      return parsedUrl.toString();
    } catch (error) {
      console.log("Error updating query parameters", error);
      return false;
    }
  };

  const fetchPdf = async (lambdaUrl) => {
    try {
      const response = await fetch(lambdaUrl);
      if (!response.ok) {
        throw new Error(`HTTP error status: ${response.status}`);
      }

      const pdfData = await response.arrayBuffer();
      return pdfData;
    } catch (error) {
      console.log("An error occured while fetching the PDF", error.message);
      return null;
    }
  };

  const handleGenerateAndCombineZip = async () => {
    console.log("selected campaign", selectedCampaign);
    setError(null);
    setMessage(null);
    if (!selectedCampaign || Object.keys(selectedCampaign).length === 0) {
      setError("Please select a campaign");
      return;
    }
    if (!selectedTemplate) {
      setError("Please select a template");
      return;
    }
    if (selectedCampaign.total < 1) {
      setError("Select a campaign that has contacts.");
      return;
    }
    setError(null);

    setLoading(true);
    setMessage(
      "This process can take some time to complete.Do not close this browser tab for any reason(s)."
    );

    try {
      const pdfArrayBuffers = await generatePdf();
      const mergedPdfBytes = await mergePdfArray(pdfArrayBuffers);
      console.log("bytes", mergedPdfBytes);
      const zipBlob = await createZipFile(mergedPdfBytes);
      setZipBlob(zipBlob);
      setLoading(false);
      setMessage(
        " Successfully generated the combined PDF and zipped it for download"
      );
    } catch (error) {
      console.error("Error processing request", error);
      setError("The operation you attempted has failed.Please contact support");
      setLoading(false);
    }
  };

  const mergePdfArray = async (results) => {
    console.log("pdf result", results);

    const mergedPdf = await PDFDocument.create();

    for (const buffer of results) {
      const pdf = await PDFDocument.load(buffer.content);
      const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      pages.forEach((page) => mergedPdf.addPage(page));
    }
    return await mergedPdf.save(); // Retruns Uint8Array
  };

  const createZipFile = async (mergedPdfBytes) => {
    const zip = new JSZip();
    zip.file("combined_contacts.pdf", mergedPdfBytes);
    return await zip.generateAsync({ type: "blob" });
  };

  const handleDownloadZip = () => {
    if (zipBlob) {
      saveAs(zipBlob, "bulk_download.zip");
    } else {
      alert("No ZIP file generated yet.");
    }
  };
  return (
    <>
      <div className="print-container">
        <h1 className="main-heading">Generate Print Collaterals</h1>
        {responseError && (
          <div className="create-print-msg">
            <Alert variant="danger">{responseError}</Alert>
          </div>
        )}
        {!responseError && (
          <div className="print-content-container">
            <FormControl
              className="print-content-form"
              style={{ width: "auto", marginTop: "5%", marginBottom: "2%" }}
            >
              <Typography
                variant="body2"
                style={{
                  marginBottom: "3%",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
                className="custom-label"
              >
                Choose a campaign
              </Typography>
              <Select
                defaultValue="For ex: SanJose-Agent Letter" // Default to first option
                style={dropdownStyles}
                onChange={(e) =>
                  setSelectedCampaign(
                    campaignsData?.find((c) => c.name === e.target.value)
                  )
                }
              >
                <MenuItem
                  value="For ex: SanJose-Agent Letter"
                  // sx={{ fontStyle: "italic", color: "#999" }}
                >
                  Select a campaign
                </MenuItem>
                {campaignsData.map((campaign, index) => (
                  <MenuItem
                    key={index}
                    value={campaign.name}
                    sx={menuItemStyles}
                  >
                    {campaign.name} ({campaign.destination_url}) (
                    {campaign.total})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: "auto" }}>
              <Typography
                variant="body2"
                style={{
                  marginBottom: "3%",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
                className="custom-label"
              >
                Choose a template
              </Typography>
              <Select
                defaultValue="template" // Default to a specific option
                style={dropdownStyles}
                onChange={(e) =>
                  setSelectedTemplate(
                    existingTemplates.find((t) => t.name === e.target.value)
                  )
                }
              >
                <MenuItem
                  value="template"
                  // sx={{ fontStyle: "italic", color: "#999" }}
                >
                  Select a template
                </MenuItem>
                {existingTemplates.map((template, index) => (
                  <MenuItem
                    key={index}
                    value={template.name}
                    sx={menuItemStyles}
                  >
                    [{template.choice}] - {template.name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="dark"
                size="lg"
                className="responsive-button"
                onClick={handleGenerateAndCombineZip}
              >
                Generate Print Collaterals
              </Button>
            </FormControl>
          </div>
        )}
      </div>

      {loading
        ? message && (
            <div>
              <div className="create-print-loading">
                <Spinner animation="border" variant="primary" />
              </div>
              <div className="create-print-msgs">
                <Alert variant="primary">{message}</Alert>
              </div>
            </div>
          )
        : message && (
            <div className="create-print-msgs">
              <Alert variant="success">{message}</Alert>
              <Button
                variant="success"
                size="lg"
                style={{ margin: "0", width: "auto" }}
                onClick={handleDownloadZip}
              >
                Download ZIP
              </Button>
            </div>
          )}
      {error && (
        <div className="create-print-msgs">
          <Alert variant="danger">{error}</Alert>
        </div>
      )}
    </>
  );
}

export default PrintContent;
