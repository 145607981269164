import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import "../CustomStyle.css";
import Form from "react-bootstrap/Form";
import { Alert } from "react-bootstrap";
import axios from "axios";
import CustomDataGrid from "../components/CustomDataGrid";
import EntityDetails from "../components/EntityDetails";
import {
  fetchCampaignData,
  validateUrl,
  generateFormData,
  campaignMapping,
  generateGridColumns,
  campaignGridColumns,
  generateGridRows,
  campaignRowMapping,
} from "../utils/PageUtils";

function CreateCampaign({ secretKey }) {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignTotalRows, setCampaignTotalRows] = useState(0);
  const [refreshCampaigns, setRefreshCampaigns] = useState(true);
  const [campaignName, setCampaignName] = useState("");
  const [destinationUrl, setDestinationUrl] = useState("");
  const [error, setError] = useState(null);
  const [info, setInfo] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const APIENDPOINT = "developer19-linqr.reachpersona.com";

  // fetch campaigns data once and integrate to data grid initially

  useEffect(() => {
    const fetchData = async () => {
      if (refreshCampaigns) {
        const existingCampaigns = await fetchCampaignData(secretKey);
        console.log("campaigns", existingCampaigns);
        if (
          typeof existingCampaigns === "object" &&
          existingCampaigns != null &&
          !Array.isArray(existingCampaigns)
        ) {
          setCampaignsData(existingCampaigns.records);
          setCampaignTotalRows(existingCampaigns.records.length);
        }
        setRefreshCampaigns(false);
      }
    };
    fetchData();
  }, [refreshCampaigns]);

  useEffect(() => {
    if (!campaignName || !destinationUrl) {
      setIsButtonDisabled(true);
      return;
    }
    setIsButtonDisabled(false);
  }, [campaignName, destinationUrl]);

  const campaignFormData = selectedCampaign
    ? generateFormData(selectedCampaign, campaignMapping)
    : null;

  const campaignColumns = generateGridColumns(campaignGridColumns);

  const campaignRows = generateGridRows(campaignsData, campaignRowMapping);

  if (campaignsData.length < 0) {
    return (
      <div className="upload-contacts-container">
        <h2>You do not have any existing campaigns</h2>
      </div>
    );
  }
  function handleClick(e) {
    setSelectedCampaign(null);
    const selected = campaignsData[e.id - 1];
    setTimeout(() => {
      setSelectedCampaign(selected);
    }, 0);
  }

  const handleSubmit = async () => {
    setError(null);
    setInfo(null);
    if (destinationUrl && !validateUrl(destinationUrl)) {
      setError(
        `Please enter a valid URL. Required format: https://yourcompany.com/landingPageX`
      );
      return;
    }
    if (campaignName && destinationUrl) {
      const payload = {
        secret_key: secretKey,
        name: campaignName,
        destination_url: destinationUrl,
      };
      await createCampaign(payload);
    }
  };

  const createCampaign = async (payload) => {
    try {
      const response = await axios.post(
        `https://${APIENDPOINT}/campaigns`,
        payload,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          validateStatus: (status) => status <= 500,
        }
      );

      if (response.status === 201) {
        setInfo(`A campaign for ${payload.name} was created successfully`);
        setCampaignName("");
        setDestinationUrl("");
        setRefreshCampaigns(true);
        return;
      } else if (response.status === 409) {
        setError(
          `A campaign under ${payload.name} already exists for user ${secretKey}`
        );
      } else if (response.status === 500) {
        console.log("error", response.statusText);
        setError(
          "The operation you attempted has failed. Please contact support."
        );
      }
    } catch (error) {
      if (error.response) {
        console.log("error", error);
        console.log("error response", error.response);
      }

      setError(
        "The operation you attempted has failed. Please contact support."
      );
    }
  };

  const handleCampaignChange = (e) => {
    setCampaignName(e.target.value);
  };

  const handleDestinationChange = (e) => {
    setDestinationUrl(e.target.value);
  };

  return (
    <>
      <div className="create-campaign-container">
        <h1 className="create-campaign-title">TraQR-Create Campaign</h1>

        <Form className="campaign-form">
          <Form.Group className="form-group-custom" controlId="campaignName">
            <Form.Label className="custom-label">
              Name your new campaign
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="For ex:Postcard-ForHayward.Empty/duplicate campaign names are not allowed."
              value={campaignName}
              onChange={(e) => handleCampaignChange(e)}
            />
          </Form.Group>
          <Form.Group
            className="form-group-custom"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="custom-label">
              Enter the URL value to redirect the user to
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="For ex: https://yourcompany.com/landingPageX- Cannot be modified later."
              value={destinationUrl}
              onChange={(e) => handleDestinationChange(e)}
            />
          </Form.Group>
          <Button
            variant="dark"
            size="lg"
            onClick={() => handleSubmit()}
            className="create-campaign-button"
            disabled={isButtonDisabled}
          >
            Create Campaign
          </Button>
        </Form>
        {error && (
          <Alert data-testid="Error-alert" variant="danger">
            {error}
          </Alert>
        )}
        {info && (
          <Alert data-testid="Success-alert" variant="success">
            {info}
          </Alert>
        )}
      </div>

      <div className="create-main-container">
        <div
          className={`create-grid-form ${
            selectedCampaign ? "grid-to-left" : "grid-centered"
          } `}
        >
          <div className="create-grid-container">
            <h3 className="heading-color">Existing Campaigns</h3>
            <CustomDataGrid
              rows={campaignRows}
              columns={campaignColumns}
              totalRows={campaignTotalRows}
              onRowClick={handleClick}
            />
          </div>
          {selectedCampaign && (
            <div className="create-form-container">
              <h3 className="heading-color">Campaign Details</h3>
              <EntityDetails inputs={campaignFormData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateCampaign;
