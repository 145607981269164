/* eslint-disable react/prop-types */
import React from "react";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const CustomDataGrid = ({ rows, columns, onRowClick }) => {
  const paginationModel = { page: 0, pageSize: 5 };
  return (
    <Paper
      sx={{
        height: "auto",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        // componentsProps={{
        //   row: { "data-testid": (row) => `unique-${row.id}` }, // Adding data-testid for rows
        //   cell: {
        //     "data-testid": (params) => `cell-${params.row.id}-${params.field}`,
        //   }, // Adding data-testid for cells
        // }}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        onRowClick={onRowClick}
        data-testid="data-grid" // Add a test ID to the DataGrid for easy identification
      />
    </Paper>
  );
};

export default CustomDataGrid;
