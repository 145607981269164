import React from "react";
import { useState } from "react";
import { Form, Button, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import axios from "axios";
import { Margin } from "@mui/icons-material";
function CreateNewUser({ secretKey }) {
  const [statusMessage, setStatusMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const APIENDPOINT = "developer19-linqr.reachpersona.com";

  const newUserFields = [
    {
      name: "name",
      label: " Your organizational name ",
      tooltip:
        "Enter the value for the name of the organization/business you wish to register with",
      placeholder: "For ex: Apple inc.",
    },
    {
      name: "email",
      label: "Your email address",
      tooltip:
        "Enter the value for the email address you wish to register with",
      placeholder: "For ex: name@example.com",
    },
    {
      name: "phone",
      label: "Your contact number",
      tooltip:
        "Enter the value for the contact number you wish to contacted with",
      placeholder: "For ex: +14152007986",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("form values", formValues);
    // Call the function here with formValues as arguments
    await userSignup(formValues.name, formValues.email, formValues.phone);
  };

  const userSignup = async (name, email, phone) => {
    const valuesAreValid = validateArgs(name, phone, email);
    if (!valuesAreValid) return;

    try {
      const response = await axios.post(
        `https://${APIENDPOINT}/users`,
        { name, email, phone },
        {
          headers: { "Content-Type": "application/json" },
          validateStatus: (status) => status <= 500,
        }
      );
      if (response.status === 201) {
        const data = response.data;
        console.log("response data", data);
        console.log("details", data.details);
        setStatusMessage(
          `An account for email  ${data.details.email}has been created successfully. Associated customer ID is ${data.details.cid}`
        );
        setIsError(false);
      } else if (response.status === 400) {
        setStatusMessage(
          "The operation you attempted has failed. Please contact support."
        );
        setIsError(true);
      } else if (response.status === 409) {
        setStatusMessage(`A user already exits for the email address ${email}`);
        setIsError(true);
      } else if (response.status === 500) {
        setStatusMessage(
          "The operation you attempted has failed. Please contact support."
        );
        setIsError(true);
      }
    } catch (error) {
      setStatusMessage(" A network request exception occured");
      setIsError(true);
    }
  };

  const validateArgs = (name, phone, email) => {
    if (!name || !phone || !email) {
      setStatusMessage(
        "One or more values to the function arguments are missing values"
      );
      setIsError(true);
      return false;
    }
    if (
      typeof name !== "string" ||
      typeof phone !== "string" ||
      typeof email !== "string"
    ) {
      setStatusMessage(
        "Invalid datatype passed to the function. All arguments must be strings"
      );
      setIsError(true);
      return false;
    }
    if (!validateUserEmail(email)) {
      setIsError(true);
      return false;
    }
    return true;
  };

  const validateUserEmail = (email) => {
    if (!email) {
      return false;
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      setStatusMessage("The email format is invalid.");
      return emailRegex.test(email);
    }
  };

  return (
    <div className="user-container">
      <h2 style={{ fontWeight: "bold" }}>Create a new user</h2>
      <Form className="proof-form-container" onSubmit={handleSubmit}>
        {newUserFields.map((field) => (
          <Form.Group
            controlId={`form-${field.name}`}
            className="form-group-custom"
            key={field.name}
          >
            <div className="modal-form-field" key={field.name}>
              <Form.Label className="custom-label"> {field.label}</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{field.tooltip}</Tooltip>}
              >
                <IconButton>
                  <HelpOutlineIcon sx={{ fontSize: 15 }} />
                </IconButton>
              </OverlayTrigger>
            </div>
            <Form.Control
              type="string"
              name={field.name}
              placeholder={field.placeholder}
              onChange={handleInputChange}
              value={formValues[field.name]}
            />
          </Form.Group>
        ))}
        <Button variant="dark" type="submit">
          Create User
        </Button>
      </Form>
      {statusMessage && (
        <div
          className={isError ? "error" : "success"}
          style={{ margin: "2% 0", width: "auto" }}
        >
          {isError ? (
            <Alert variant="danger">{statusMessage}</Alert>
          ) : (
            <Alert variant="success">{statusMessage}</Alert>
          )}
        </div>
      )}
    </div>
  );
}

export default CreateNewUser;
